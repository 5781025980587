import { Helmet, HelmetProps } from "react-helmet";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { SchemaOrg } from "./SchemaOrg";

interface SeoProps {
  datePublished?: string;
  description?: string;
  image?: string;
  isArticle?: boolean;
  lang?: string;
  meta?: HelmetProps["meta"];
  title?: string;
  url?: string;
}

const Seo = ({
  description,
  lang = "en",
  meta = [],
  title,
  url,
  image,
  isArticle = false,
  datePublished,
}: SeoProps) => {
  const { site } = useStaticQuery(
    graphql`
      query Seo {
        site {
          siteMetadata {
            title
            description
            siteUrl
            author {
              name
              url
            }
          }
        }
      }
    `
  );

  const seo = site.siteMetadata;
  const metaDescription = description || seo.description;
  const defaultTitle = seo.title;

  const ogType = isArticle ? "article" : "website";

  const defaultMeta = [
    {
      name: "charSet",
      content: "utf-8",
    },
    {
      name: `description`,
      content: metaDescription,
    },
    {
      property: `og:title`,
      content: title,
    },
    {
      property: `og:description`,
      content: metaDescription,
    },
    {
      property: `og:type`,
      content: ogType,
    },
    url ? { property: `og:url`, content: url } : undefined,
    image ? { property: `og:image`, content: image } : undefined,
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      name: `twitter:creator`,
      content: site.siteMetadata?.author?.name || ``,
    },
    {
      name: `twitter:title`,
      content: title,
    },
    {
      name: `twitter:description`,
      content: metaDescription,
    },
    image ? { name: `twitter:image`, content: image } : undefined,
    url ? { name: `twitter:url`, content: url } : undefined,
  ].filter(Boolean) as NonNullable<HelmetProps["meta"]>;

  return (
    <>
      <Helmet
        htmlAttributes={{ lang }}
        title={title}
        titleTemplate={defaultTitle ? `%s — ${defaultTitle}` : undefined}
        meta={defaultMeta.concat(meta)}
      />

      <SchemaOrg
        isArticle={isArticle}
        url={url}
        title={title}
        image={image}
        description={description}
        datePublished={datePublished}
        websiteUrl={seo.siteUrl}
        author={seo.author.name}
        websiteName={seo.title}
      />
    </>
  );
};

export default Seo;
