import React from "react";
import { GlobalStyle } from "../components/GlobalStyle";
import { PageContainer } from "../components/PageContainer";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";
import styled from "styled-components";
import Seo from "../components/Seo";

const Heading1 = styled.h1`
  font-family: "Barlow Condensed", sans-serif;
  font-size: 48px;
  font-weight: bold;
  margin: 0 auto 24px;
  max-width: 320px;
  text-align: center;
`;

const Content = styled.div`
  margin: 0 auto;
  max-width: 320px;
  text-align: center;
  font-family: "Barlow Condensed", sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: #333;
`;

export default () => (
  <>
    <Seo title="A propos" />
    <GlobalStyle />
    <PageContainer>
      <Header />
      <Heading1>A propos</Heading1>
      <Content>
        <img
          src="https://images.unsplash.com/photo-1518791841217-8f162f1e1131?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60"
          alt="Un chat allongé qui regarde l'objectif"
          loading="lazy"
        />
        perdre-kilos.fr est un site de perte de poids qui permet de découvrir
        des recettes, des astuces et des conseils pour perdre des kilos.
      </Content>
    </PageContainer>
    <Footer />
  </>
);
