import {createGlobalStyle} from "styled-components";
import "the-new-css-reset/css/reset.css"


export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, 'DM Sans', sans-serif, serif;
    font-size: 16px;
    line-height: 1.5;
    color: #232129;
    background-color: #fafafa;
  }
`;