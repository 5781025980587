import { Helmet } from "react-helmet";
import React from "react";

interface SchemaOrgProps {
  author?: string;
  datePublished?: string;
  description?: string;
  image?: string;
  isArticle?: boolean;
  title?: string;
  url?: string;
  websiteName: string;
  websiteUrl: string;
}

export const SchemaOrg = ({
  isArticle,
  url,
  title,
  image,
  description,
  datePublished,
  author,
  websiteUrl,
  websiteName,
}: SchemaOrgProps) => {
  const schemaOrgJSONLD: Array<Record<string, string | any>> = [
    {
      "@context": "http://schema.org",
      "@type": "WebSite",
      url: websiteUrl,
      name: websiteName,
    },
  ];
  if (isArticle) {
    schemaOrgJSONLD.push({
      "@context": "http://schema.org",
      "@type": "BreadcrumbList",
      itemListElement: [
        {
          "@type": "ListItem",
          position: 1,
          item: {
            "@id": url,
            name: title,
            image,
          },
        },
      ],
    });
    schemaOrgJSONLD.push({
      "@context": "http://schema.org",
      "@type": "BlogPosting",
      url,
      name: title,
      headline: title,
      image: {
        "@type": "ImageObject",
        url: image,
      },
      description,
      author: {
        "@type": "Person",
        name: author,
      },
      mainEntityOfPage: {
        "@type": "WebSite",
        "@id": url,
      },
      datePublished,
    });
  }

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(schemaOrgJSONLD)}
      </script>
    </Helmet>
  );
};
