import styled from "styled-components";

export const PageContainer = styled.main`
  color: #232129;
  padding: 66px 0 0 0;
  position: relative;
  max-width: 1200px;
  margin: 0 auto;

  @media (max-width: 800px) {
    padding: 0 12px;
  }
`;
