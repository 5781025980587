import React from "react";
import styled from "styled-components";

const Container = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
  font-size: 14px;
  color: #333333;
  margin-top: 24px;
  padding: 48px;
`;

export const Footer = () => (
  <Container>perdre-kilos.fr © {new Date().getFullYear()}</Container>
);
