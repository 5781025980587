import styled from "styled-components";
import * as React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import { ucfirst } from "../helpers/ucfirst";

const Heading = styled.h1`
  margin: 0 auto 24px;
  max-width: 320px;
  text-align: center;
  font-family: "Alegreya", serif;
  font-size: 48px;
`;

const TopNavigation = styled.nav<{ isOpen: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
  border-bottom: 1px solid #e9e9e9;
  padding-bottom: 24px;
  flex-direction: column;
  gap: 1rem;

  button {
    @media (min-width: 768px) {
      display: none;
    }
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    @media (max-width: 768px) {
      display: ${(props) => (props.isOpen ? "flex" : "none")};
    }

    list-style: none;
    margin: 0;
    padding: 0;
    gap: 1rem;

    li {
      a {
        color: black;
        font-weight: bold;
        font-size: 16px;
        text-decoration: none;
        padding: 8px;

        &:hover {
          text-decoration: underline;
        }
      }

      a.active {
        color: crimson;
      }
    }

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 24px;
    }
  }
`;

export function Header() {
  const data = useStaticQuery<Queries.HeaderComponentQuery>(graphql`
    query HeaderComponent {
      allMdx {
        nodes {
          frontmatter {
            tags
          }
        }
      }
    }
  `);

  const allTagsArray = Array.from(
    new Set(
      (data.allMdx.nodes || [])
        .map((node) => node.frontmatter?.tags || [])
        .reduce((acc, curr) => acc.concat(curr), [])
        .filter((tag) => tag)
    )
  );

  const [isOpen, setIsOpen] = React.useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Link to="/">
        <Heading>perdre-kilos.fr</Heading>
      </Link>
      <TopNavigation isOpen={isOpen}>
        <button onClick={toggleNavbar}>{isOpen ? "Fermer" : "Menu"}</button>
        <ul>
          <li>
            <Link to="/" activeClassName="active">
              Accueil
            </Link>
          </li>
          {allTagsArray.map((tag) =>
            tag ? (
              <li key={`header-tag-link-${tag}`}>
                <Link key={tag} to={`/tags/${tag}`} activeClassName="active">
                  {ucfirst(tag)}
                </Link>
              </li>
            ) : null
          )}
          <li>
            <Link to="/about" activeClassName="active">
              A propos
            </Link>
          </li>
        </ul>
      </TopNavigation>
    </>
  );
}
